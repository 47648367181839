// Reset box sizing to border box
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Remove figure margin
figure {
  margin: 0;
}