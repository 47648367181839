$color-primary: #FEA385;
$color-secondary: #ffefed;

._gr_article_builder {
	border: 1px solid rgb(217, 217, 217);
	border-radius: 2px;
	border-color: {}
	transition: border-color .3s ease-in-out;

	&:hover {
		border-color: $color-primary; }

	.flex {
		display: flex;

		&.direction-row {
			flex-direction: row; }
		&.direction-col {
			flex-direction: column; }
		&.justify-center {
			justify-content: center; }
		&.justify-start {
			justify-content: flex-start; }
		&.justify-end {
			justify-content: flex-end; }
		&.justify-between {
			justify-content: space-between; }
		&.align-start {
			align-items: start; }
		&.align-center {
			align-items: center; }
		&.align-end {
			align-items: end; }
		&.wrap {
			flex-flow: wrap; } }

	.area {
		display: flex;
		flex-direction: column;
		background-color: transparent;

		.add-component-container {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 15px;

			.add-component-button {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $color-primary;
				height: 40px;
				color: white;
				font-weight: 600;
				padding: 4px 20px;
				font-size: 14px;
				text-transform: uppercase;
				border-radius: 4px;
				cursor: pointer;
				transition: opacity .3s ease-in-out;
				margin: 0 10px;
				box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.1);

				&:hover {
					opacity: 0.8; } } }

		.grid {
			display: flex;
			justify-content: center;
			min-height: 252px;

			.item-list {
				width: 100%;
				min-height: 180px;
				padding-top: 15px;

				&.dragging-over {
					transition: background-color .3s ease-in-out;
					background-color: $color-secondary;
					border-radius: 2px; }

				.item {
					background: transparent;

					label.input-label {
						font-weight: bold; }

					.header {
						& > div {
							float: left;
							min-width: 40px;
							height: 25px;
							text-align: center;
							border: 1px solid #efefef;
							border-radius: 5px;
							border-bottom: none;
							border-bottom-left-radius: 0;
							border-bottom-right-radius: 0;
							font-size: 11px;
							margin: 0 5px 0 0;
							padding: 4px 0 3px 0;
							transition: all 200ms ease-in-out;
							background: center no-repeat;
							background-color: white; }

						.tab-button {
							display: flex;
							justify-content: center;
							align-items: center;
							min-width: 50px;
							padding: 4px 10px;
							font-size: 10px;
							font-family: Poppins, sans-serif;
							background-color: white;
							text-transform: uppercase;
							font-weight: bold;
							letter-spacing: 0.5px;
							border: 1px solid #efefef;

							& > div {
								display: flex;
								align-items: center;
								justify-content: center;
								background: center no-repeat;
								height: 10px;
								width: 11px; }

							&.handle {
								color: #464646;
								cursor: move;

								&:hover {
									background-color: #5758BB;
									border: 1px solid #5758BB; } }

							&.add {
								cursor: pointer;
								color: #464646;

								&:hover {
									background-color: #39CB86;
									border: 1px solid #39CB86; } }

							&.delete {
								cursor: pointer;
								color: #464646;

								&:hover {
									background-color: #FF4D4D;
									border: 1px solid #FF4D4D; } }

							&.name {
								cursor: default;
								color: #464646; } } }

					.border-box {
						margin-bottom: 15px;
						padding: 15px 0 0 0;
						background: white;
						border: 1px solid #efefef;
						border-radius: 5px;
						border-top-left-radius: 0;
						box-shadow: 0 5px 17px 0 rgba(0, 0, 0, 0.03);
						transition: all 200ms ease-in-out;
						//min-height: 200px
						display: flex;
						align-items: center;
						justify-content: center;

						p {
							margin-bottom: 0; }

						&.handle {
							border: 1px solid #5758BB;
							background-color: #F6F6FD; }

						&.add {
							border: 1px solid #39CB86;
							background-color: #F4F9F7; }

						&.delete {
							border: 1px solid #FF4D4D;
							background-color: #FFFAFA; }

						.inner-items {
							width: 100%;
							min-height: 180px;
							margin-top: -16px;
							padding-top: 16px;
							border-top-left-radius: 0 !important;

							&.allow-options {
								border-top-right-radius: 0 !important; }

							&.dragging-over {
								transition: background-color .3s ease-in-out;
								background-color: $color-secondary;
								border-radius: 5px; } }

						.custom-grid-item {
							width: 100%;
							margin: 0 15px 15px 15px;

							.inner-items {
								[data-rbd-draggable-context-id] {
									~ [data-rbd-placeholder-context-id] {
										display: none !important; } }
								&.is-dragging-over {
									[data-rbd-placeholder-context-id] {
											display: block !important; } } }

							&.faq {
								.questions-list {
									padding: 15px;
									border: 1px solid #efefef; }

								.question {
									margin-bottom: 15px;
									padding: 15px;
									border: 1px solid #efefef; }

								.title-wrapper {
									display: flex; } } }

						.custom-item {
							width: 100%;
							margin: 0 15px 15px 15px;

							&.batch {
								.batch-item {
									margin-bottom: 15px;
									border-bottom: 1px solid #EDECEC;
									padding-bottom: 15px; } }

							&.reference {

								.block {
									display: flex;
									border-bottom: 1px solid #efefef;
									margin-bottom: 20px;

									.ant-upload-list {
										display: flex; }

									.ant-upload-picture-card-wrapper {
										display: flex; }

									.ant-upload.ant-upload-select-picture-card {
										min-width: 104px; } } }

							&.colored-box {
								display: flex;
								padding-bottom: 36px;

								.text-area {
									min-height: 220px; }

								.color-picker {
									margin-left: 15px; } }

							&.image {
								width: 100%;

								._gr_article_builder-upload-field {
									width: 100%;
									height: 100%; }

								img._gr_article_builder-upload-image {
									max-height: 300px;
									max-width: 100%; }

								.ant-upload.ant-upload-select-picture-card {
									width: 100%;
									height: 100%;
									margin: 0px; } }

							&.redirect-element {

								.blog-post-chooser {
									margin: 0 15px 15px 0;
									width: 100%; } }

							.custom-item-image-wrapper {
								max-height: 318px;
								@media (max-width: 992px) {
									margin-bottom: 15px; }

								span {
									margin-bottom: 0px; } } } }

					&.dragging {
						border: 2px solid #5758BB;
						border-radius: 5px;
						padding: 0;
						background-color: #F6F6FD; } } } } } }

._gr_article_builder-modal {

	.justify {
		display: flex;
		justify-content: center;
		align-items: center; }

	.component-button {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		margin: 10px;
		background: #F9F9FC;
		border-radius: 4px;
		font-size: 14px;
		color: black;
		text-transform: none;
		border: 1px solid #EDECEC;
		font-weight: 600;
		cursor: pointer;
		transition: color .2s ease-in-out;

		&:hover {
			color: $color-primary; } } }

.css-57uqir {
	margin: 10px -15px !important; }
