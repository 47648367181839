html, body, #root {
	font-family: Ubuntu, sans-serif; }

#root {
	width: 100%;
	height: 100%; }

$color-primary: #FEA385;
$color-secondary: #FFD4A7;
$color-font: #444953;
$color-placeholder: #D3D2DD;
$color-border: #D3D2DD;
$color-border-hover: #A0A1A2;
$color-radio-border: #BFBECE;
$color-success-green: #94E597;
$color-error-red: #D84315;
$color-danger: #EF2424;
$color-menu-item: #A4502C;
$color-background: #F5F7FB;
$color-challenge-font: #3E2022;

$color-light: #FFFFFF;
$color-gray: #ababab;
$color-dark-gray: #444953;
$color-empty: #e6e5f0;
$color-footer-background: #525968;
$color-label: #525968;
$color-label-light: #8A909D;
$color-gray-label: #646D80;
$color-calendar-border: #E1E0ED;
$color-success-green-1: #79D17C;
$color-progress-bar: #EDEDEE;
$color-tag: #F8F8F8;
$color-border-light: #eeeeee;
$color-border-active: #ffc5ad;

$gradient-primary: linear-gradient(88deg, #E69175 35%, #FFD4A7 124%);
$gradient-modal: linear-gradient(66deg, #ECA790 39%, #F1C69A 120%);
$gradient-budge: linear-gradient(90deg, #ECA790 0%, #F1C69A 100%);

.site-layout-background {
	padding: 0 !important;
	background: #fff !important; }

.app-logo {
	height: 48px;
	background-image: url('../assets/images/logos/fitShakerLight.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin: 8px; }

.flex {
	display: flex; }

.inline-flex {
	display: inline-flex; }

.flex, .inline-flex {
	&.direction-row {
		flex-direction: row; }
	&.direction-col {
		flex-direction: column; }
	&.justify-center {
		justify-content: center; }
	&.justify-start {
		justify-content: flex-start; }
	&.justify-end {
		justify-content: flex-end; }
	&.justify-between {
		justify-content: space-between; }
	&.align-start {
		align-items: start; }
	&.align-center {
		align-items: center; }
	&.align-end {
		align-items: end; }
	&.wrap {
		flex-flow: wrap; } }

.margin-auto {
	margin: auto;
	&.auto-vertical {
		margin-left: 0;
		margin-right: 0; }
	&.auto-horizontal {
		margin-top: 0;
		margin-bottom: 0; } }

.border-1,
.border-top,
.border-bottom,
.border-left,
.border-right {
	border: 1px solid $color-border-light;
	&.border-top {
		border-width: 0;
		border-top-width: 1px; }
	&.border-bottom {
		border-width: 0;
		border-bottom-width: 1px; }
	&.border-left {
		border-width: 0;
		border-left-width: 1px; }
	&.border-right {
		border-width: 0;
		border-right-width: 1px; } }

.width-100 {
	width: 100%; }

.ant-table-cell {
	cursor: default;

	.note {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis; } }

.general-table {
	&.overflow-x-scroll {
		overflow-x: auto;

		margin-top: 15px; }

	tr {
		td {
			cursor: pointer; } }

	&.promo-codes-usage {
		tr {
			td {
				cursor: default; } } } }

label.general-label {
	font-weight: bold; }

.flex-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	height: 100%; }

.login-logo {
	height: 80px;
	background-image: url('../assets/images/logos/fitShakerOrange.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-bottom: 30px; }

.profile-dropdown-item {
	margin-right: 20px;
	cursor: pointer; }

.required:after {
	color: $color-error-red;
	content: '*'; }

.upload-field {
	width: 100px;
	height: 100px;

	img {
		max-width: 100px;
		max-height: 100px; }

	&.upload-with-remove {
		.ant-upload {
			position: relative;
			.upload-remove-button {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 16px;
				top: 4px;
				right: 3px;
				width: 26px;
				min-width: 26px;
				height: 26px;
				color: $color-light;
				background-color: rgba($color-gray, 0.6);
				border-radius: 50%;
				transition: all .2s ease-in-out;
				&:hover {
					background-color: $color-danger; } } } } }

.content-box {
	padding: 20px 0 10px 0; }

.tooltip-error {
	color: red; }

input, textarea {
	&:required, &:invalid {
		box-shadow: none; } }

.input-wrapper {
	margin-bottom: 10px;

	label {
		font-weight: bold;

		&.required:after {
			color: $color-error-red;
			content: " *"; }

		.counter {
			background-color: $color-primary;
			color: white;
			padding: 2px 10px;
			font-weight: normal;
			border-radius: 10px; } }

	.disabled {
		border: none; }

	.action-buttons {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between; }

	.circle-picker {
		width: 100% !important; }

	.color-picker-wrapper {
		position: relative;
		.preview {
			display: inline-flex;
			align-items: center;
			gap: 8px;
			cursor: pointer;
			background: none;
			outline: none;
			border: none;
			.circle {
				width: 28px;
				height: 28px;
				border-radius: 50%;
				transform-origin: center;
				transition: 100ms ease-in-out;
				box-shadow: rgba(0,0,0,0.15) 0px 0px 0px 1px inset; }

			&:hover,
			&:focus-visible {
				.circle {
					transform: scale(1.1); } } }
		.color-picker {
			display: none;
			max-width: 350px;
			user-select: none;
			&.is-open {
				padding-top: 8px;
				display: block; } } }

	.chrome-picker {
		width: 100% !important; } }

.ant-transfer-list-header-dropdown {
	display: none; }

.dashboard-chart-wrapper {
	border: 1px solid $color-border-light;
	border-radius: 4px;
	padding: 16px; }

.ant-input-search .ant-input-group {
	border-radius: 4px 0 0 4px; }

.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal {
	border-radius: 6px 6px 0 0 !important; }

.ant-notification-notice, .ant-modal-content, .ant-modal-header {
	border-radius: 8px; }

.ant-btn, .ant-pagination-item-active, .ant-select-selector, .ant-badge.ant-badge-not-a-wrapper sup, img {
	border-radius: 4px !important; }

.ant-picker, .ant-input {
	width: 100%;
	border-radius: 4px; }

.ant-input {
	&:disabled {
		color: rgba(0, 0, 0, 0.65);
		background-color: white;
		padding-left: 0; } }

.ant-input, .exercise-order {
	height: 46px; }

.page-wrapper {
	.avatar-uploader > .ant-upload {
		max-width: 128px;
		max-height: 128px; }

	img.upload-image {
		width: 100%;
		max-width: 84px;
		max-height: 84px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain; }

	.translations {
		margin-top: 10px;
		overflow: visible; }

	.grid {

		.main-content {
			background-color: white;
			padding: 20px;
			border-radius: 4px; }

		.sidebar-content {
			background-color: white;
			padding: 20px;
			border-radius: 4px;

			.ant-btn {
				margin: 10px 0 !important; }

			.ant-tooltip-disabled-compatible-wrapper .ant-btn {
				width: 100%; } } }

	.landing-section {
		margin-bottom: 40px; } }

.not-found-page {
	.image-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.not-found-image {
			height: 400px;
			width: 550px;
			background: center no-repeat; }

		.title {
			font-family: Poppins;
			font-size: 40px;
			margin-bottom: 40px; }

		.redirect-button {
			margin-bottom: 40px; } } }

.paymentFilter {
	&.checkboxes {
		margin: 0px 0px 30px 0px;
		background-color: white;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		max-width: 730px; }
	.ant-form-item {
		margin-bottom: 0; }
	.ant-form-item-control-input .ant-checkbox-wrapper {
		margin: 0px 16px 0px;
		padding: 6px 16px 6px 0px; } }

.page-builder {
	margin-top: 20px;
	padding: 20px;
	background-color: white; }

.grecaptcha-badge {
	visibility: hidden; }

.recaptcha-terms {
	p {
		font-size: 11px;
		line-height: 130%;
		color: $color-font;
		opacity: 0.8;

		span {
			font-weight: 300; }

		a {
			font-weight: 300;
			color: $color-primary;
			text-decoration: underline;

			&:hover {
				color: $color-primary !important;
				opacity: 0.8; } } } }

.ant-table-cell {
	img.logo {
		height: 30px;
		width: auto; }
	&.thumbnail-column {
		width: 100px;
		img.thumbnail-image {
			width: 100%; } } }

@media (max-width: 768px) {
	.dashboard-page {
		.ant-row {
			flex-wrap: wrap; }

		.ant-col-8 {
			flex: 0 0 100%;
			wdith: 100%;
			max-width: 100%; } } }

.dashboard-page {
	.ant-tabs-tab {
		padding: 6px 24px;
		border-radius: 8px;
		border: 1px solid #c9c9c9;
		font-size: 16px;
		&:hover {
			border-color: #fea385;
			color: black; }
		&.ant-tabs-tab-active {
			background-color: #fea385;
			border-color: #fea385;
			.ant-tabs-tab-btn {
				color: white; } } }
	.ant-tabs-ink-bar {
		display: none; } }

.dashboard-card {
	border-radius: 14px;
	margin-bottom: 20px;
	background-color: white;
	padding: 20px;
	.card-content {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap; }
	.ant-table.ant-table-small .ant-table-tbody > tr > td {
		padding: 4px 8px; }
	.ant-statistic-content-value-int, .ant-statistic-content-suffix, .ant-statistic-content-value-decimal {
		font-size: 18px; } }

.lng-flag {
	width: 20px;
	height: 20px; }

.overlay-loading {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255,255,255, 0.5);
	display: flex;
	justify-content: center;
	align-items: center; }

.row-dragging {
	background: #fafafa;
	border: 1px solid #ccc; }

.row-dragging td {
	padding: 16px;
	visibility: hidden; }

.row-dragging .drag-visible {
	visibility: visible; }

.detail-buttons {
	.ant-btn {
		margin: 0.32em 15px 0.32em 0;
		&:last-child {
			margin-right: 0; } } }

.draggable-table-field {
	tr.drop-over-downward {
		td {
			border-bottom: 2px dashed $color-primary; } }

	tr.drop-over-upward {
		td {
			border-top: 2px dashed $color-primary; } } }

.dashboard-page {
	.ant-collapse {
		background-color: rgba(0,0,0,0);
		.ant-collapse-header {
			font-size: 24px;
			font-weight: 500;
			padding-bottom: 4px;
			padding-top: 4px; }
		.ant-collapse-content-box {
			padding-bottom: 0px; } }
	.ant-collapse-borderless > .ant-collapse-item {
		border-bottom: none; } }

.wysiwyg {
	h1, p {
		margin: 0; }
	ul, ol {
		margin: 0 0;
		padding-inline-start: 40px; }
	li {
		margin: 4px 0; } }

.wysiwyg-editor-input-field {
	transition: all 0.2s;
	border-radius: 4px;
	border: 1px solid #d9d9d9;
	padding: 4px 8px;
	div:first-of-type {
		padding: 0 0 4px 0 !important;
		margin: 0 0 8px 0 !important; } }


.wysiwyg-editor-input-field .css-57uqir {
	margin: 0 !important;
	padding: 8px 8px;
	border: 1px solid #d9d9d9;
	border-radius: 2px 2px 0px 0px;
	border-bottom: 2px solid rgba(#d9d9d9, 0.4);
	transition: all 0.2s; }

.wysiwyg-editor-input-field .css-57uqir + div {
	padding: 16px;
	min-height: 120px;
	border: 1px solid #d9d9d9;
	border-top: none;
	border-radius: 0px 0px 2px 2px;
	transition: all 0.2s; }

.wysiwyg-editor-input-field.active {
	box-shadow: 0 0 7px -1px $color-border-active; }

.wysiwyg-editor-input-field:hover .css-57uqir, .wysiwyg-editor-input-field:hover .css-57uqir + div {
	border-color: $color-border-active; }

.wysiwyg-editor-input-field:hover .css-57uqir {
	border-bottom-color: rgba($color-border-active, 0.25); }

.wysiwyg-editor-input-field.active .css-57uqir, .wysiwyg-editor-input-field.active .css-57uqir + div {
	border-color: $color-border-active; }

.wysiwyg-editor-input-field.active .css-57uqir {
	border-bottom-color: rgba($color-border-active, 0.25); }

.no-challenge-notification {
	font-size: 16px;
	min-height: 63.2px;
	margin: 0;
	color: rgba(0, 0, 0, 0.45); }

.general-table.extended {
	.ant-table .ant-table.ant-table-small {
		margin: -20px -8px -8px 16px !important;
		td {
			background-color: #fbfbfb; } } }

.table-select-input-wrapper {
	margin-bottom: 0; }

.ant-row {
	border-radius: 4px; }

.user-program-form .ant-row {
	.center-content.questions-form .input-wrapper {
		width: 100% !important; } }

.fs-arrow-button {
	display: flex;
	width: 32px;
	height: 15px;
	padding: 0;
	margin: 0;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	.anticon {
		font-size: 13px;
		margin-top: 1px; } }

.questions-expander {
	margin: -4px;
	.question-table {
		border: 1px solid #f0f2f5;
		div {
			padding: 2px 4px;
			&.ant-row:last-of-type {
				border-top: 1px solid #f0f2f5; }
			&.question {
				font-weight: 800;
				background-color: #f0f2f5; }
			span {
				color: #bdbdbd;
				font-style: italic; } } } }

.cover-loading {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.5);
	height: 50px;
	width: 100%;
	z-index: 2; }

.search-bar {
	margin-bottom: 18px; }

.highlighted {
	padding: 16px;
	border-radius: 4px;
	margin-top: 4px;
	margin-bottom: 12px; }

.async-trans-field-colapse {
	position: relative;
	&.ant-collapse.ant-collapse-borderless {
		border-radius: 4px;
		border: 1px solid #d9d9d9 !important;
		margin-bottom: 8px; }
	.buttonWrapper {
		position: absolute;
		text-align: center;
		z-index: 1;
		right: 120px;
		margin: 8px; }
	.counter {
		text-align: center;
		border: 1px solid #FEA385;
		border-radius: 4px;
		margin: 14px;
		padding: 0 8px;
		font-size: 12px;
		position: absolute;
		z-index: 1;
		right: 0px; }
	.ant-collapse-header {
		position: relative;
		border-radius: 4px !important;
		background-color: white;
		font-weight: bold;
		color: #646464 !important; }
	.ant-collapse-content-box {
		padding: 4px; } }

.program-exercises-collapse {
	&.ant-collapse {
		margin-bottom: 8px; }
	.ant-collapse-header {
		position: relative;
		border-radius: 4px !important;
		background-color: white;
		font-weight: bold;
		color: #646464 !important; } }

.async-picker-delete-button.ant-btn {
	width: 90px;
	&.with-custom-button {
		width: 40px; } }

.category-item {
	display: flex;
	align-items: center; }

.drag-handle {
	cursor: move;
	color: #999;
	width: 30px;
	margin-right: 30px; }

.true-cell, .false-cell {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 50%; }

.true-cell {
	background-color: $color-success-green-1; }

.false-cell {
	background-color: $color-border-light; }

.exercise-list-table {
	.col-exercise-name {
		width: 30%; } }

.center-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	.input-wrapper {
		width: 90%;
		.input-field {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center; }
		.upload-field {
			width: 200px;
			height: 300px;
			div {
				width: 100%;
				height: 100%; } }
		.upload-image {
			max-width: 200px;
			max-height: 300px; } } }

.logo-uploader-dark {
	.ant-upload {
		color: $color-placeholder;
		background-color: $color-dark-gray;
		border-color: $color-placeholder; } }

.user-program-table {
	.submit-button {
		width: 100%;
		margin-top: 16px; }
	tr.ant-table-expanded-row {
		td.ant-table-cell {
			padding: 0 1px 0 1px;
			background-color: #f0f0f0; }
		form {
			margin: 4px;
			padding: 48px;
			border-radius: 16px;
			background-color: #f5f5f5; } } }

.program-select {
	&.input-wrapper {
		margin-left: -8px;
		width: 208px; } }

.ant-table-row.bold {
	font-weight: bold; }

.ant-table-row.disabled-table-row > td:hover {
	cursor: not-allowed; }

.socials-label {
	display: block;
	margin-bottom: 8px; }

.program-type {
	border-radius: 15px;
	display: inline-block;
	padding: 2px;
	width: 150px;
	color: white;
	font-weight: bold; }

.socials-icons > span {
	margin-right: 7px; }

.input-textarea {
	font-size: 14px; }

.ant-modal-body {
	margin-bottom: 76px; }

.program-modal {
	.ant-modal-body {
		margin-bottom: 0px; } }

.user-program-form {
	.tooltip-error {
		height: 8px; } }

.thumbnail-generator-modal {
	.ant-modal-body {
		margin-bottom: 0; }
	.hint {
		color: #a5a5a5;
		font-style: italic; }
	.input-wrapper:last-of-type {
		margin-left: 16px; } }

.ua {
	background-color: $color-border-light;
	border-radius: 4px;
	font-size: 12px;
	padding: 8px; }

.thumbnail-gif {
	span {
		font-weight: 500; }
	img {
		padding: 4px;
		width: 100%;
		border: 1px solid #d9d9d9;
		background-color: #f5f5f5; }
	div {
		background-color: #f5f5f5;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 50px;
		color: #d9d9d9;
		width: 100%;
		height: 125px;
		border: 1px solid #d9d9d9; } }

.generator-overview {
	position: fixed;
	display: flex;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 3;
	padding: 64px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	flex-wrap: wrap;
	align-content: center;
	justify-content: center;
	flex-direction: column;
	.loading-wrapper {
		width: 500px;
		display: flex;
		height: auto;
		background-color: white;
		padding: 32px;
		align-content: center;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		.generation-description:last-of-type {
			margin: 32px 0 16px;
			color: #a5a5a5;
			font-style: italic; }
		.generation-description:first-of-type {
			font-weight: 500; }
		.generation-description {
			display: flex;
			justify-content: center;
			span:last-child {
				display: inline-block;
				margin-left: 24px; } } } }

.radio-group-wrapper {
	display: flex;
	flex-wrap: wrap;
	height: auto;
	align-items: flex-end;
	line-height: 0;
	margin-bottom: 10px;

	& > * {
		margin-bottom: 5px; }

	.radio-group-label {
		line-height: 12px;

		&.radio-group-label-min {
			margin-right: 10px; }

		&.radio-group-label-max {
			margin-right: 10px; }

		@media (max-width: 1200px) {
			display: none; } }

	.option {
		position: relative;
		cursor: pointer;
		user-select: none;
		font-family: Ubuntu, sans-serif;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: $color-font;
		transition: all 200ms ease-in-out;
		padding: 0;
		width: 20px;

		&:last-of-type {
			margin-right: 10px; }
		&:hover {
			.checkmark {
				border: 2px solid $color-primary; } }

		.text {
			.title {
				font-weight: 400; }
			.description {
				font-weight: 400; } }

		.checkmark {
			position: absolute;
			background-color: white;
			border: 2px solid $color-radio-border;
			border-radius: 50%;
			position: relative;
			width: 12px;
			height: 12px;
			left: 0;
			top: 0;
			transform: translateY(0);

			&:after {
				content: "";
				position: absolute;
				opacity: 0;
				transition: opacity 200ms ease-in-out; } }

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			width: 0;
			height: 0;

			&:focus ~ .checkmark {
				border: 2px solid #D6785C; }

			&:checked ~ .checkmark:after {
				opacity: 1;
				border-radius: 50%;
				background: $color-primary;
				border: 2px solid $color-primary;
				box-sizing: border-box;
				left: -2px;
				top: -2px;
				width: 12px;
				height: 12px; } } }

	.option-selected {
		.checkmark {
			border: 2px solid #D6785C; } } }

.pages-language-select {
	display: flex;
	.ant-row.ant-form-item {
		margin-bottom: 0px; } }

.highlight {
	.input-field {
		font-size: 24px;
		font-weight: bold; } }

.dark_bg {
	background-color: #f0f2f5;
	padding: 16px;
	margin: 16px 0;
	border-radius: 4px; }

@media (max-width: 1275px) {
	.exercise-list-table {
		.col-exercise-name {
			width: 24%; } } }
